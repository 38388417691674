<template>
    <div class="col-md-8 offset-md-2">
                            <div class="mb-3">
                                <span class="h4 titulosg"> <strong>{{titulo}}</strong> <span class="text-secondary">( {{datospermiso.nombrepermiso}} ) </span> </span> <br>
                                <span class="text-secondary">Complete el formulario, los campos con * son necesarios</span>
                            </div>
                            

                            <form v-on:submit.prevent="ejecutar"> 
                            <div class="card card-body mb-3">


 
          
                                <div class="mb-3">
                                        <label for="permiso" class="form-label">Nombre del Permiso: <span class="text-danger">*</span></label>
                                        <input v-model="datospermiso.nombrepermiso" type="text" required class="form-control" id="permiso" placeholder="Nombre del Permiso" >
                                </div>

                                <div class="row mb-3">
                                    <div class="col-md-6">
                                        <label for="icono" class="form-label">Icono:    <span v-html="datospermiso.icono" class="text-danger"></span></label>  
                                        <input v-model="datospermiso.icono" type="text" class="form-control" id="icono" >
                                    </div>
                                    <div class="col-md-6">
                                        <label for="linkpermiso" class="form-label">Link: </label>
                                        <input v-model="datospermiso.linkpermiso" type="text" class="form-control" id="linkpermiso">
                                    </div>
                                </div>

                                <div class="mb-3">
                                    <label for="permisos_idpermisos" class="form-label">Permiso Padre: <span class="text-danger">*</span></label>
                                        <select v-model="datospermiso.permisos_idpermisos" name="permisos_idpermisos" id="permisos_idpermisos" class="form-select">
                                            <option value="">-</option>
                                            <option  v-for="permiso in permisospadres.filter(permisos => permisos.permisos_idpermisos === null)" :key="permiso.idpermisos"  :value="permiso.idpermisos"> 
                                                {{ permiso.nombrepermiso }}
                                            </option>
                                        </select>
                                </div>

                                <div class="mb-3">
                                    <label for="descripcion" class="form-label">Descripción del permiso:  </label>
                                    <textarea v-model="datospermiso.descripcion" class="form-control"  rows="6"></textarea>
                                </div>

                                <div class="mb-3">
                                    <label for="orden" class="form-label">Orden:  </label>
                                    <input v-model="datospermiso.orden" type="number" class="form-control" >
                                </div>



                            </div>

                            <div class="card card-body mb-3">
                                <strong class="mb-3">Categorías Asociadas:</strong>


                                <div class="form-check" v-for="item in categorias" :key="item.idpersonascategoria">
                                <input class="form-check-input" type="checkbox" :value="item.idpersonascategoria" :id="'c'+item.idpersonascategoria" v-model="categoriasasociadas" >
                                <label class="form-check-label" :for="'c'+item.idpersonascategoria">
                                    {{item.nombrecategoria}}  
                                </label>
                                </div>

                            </div>
                            <div class="mb-5">
                                <button type="submit" class="btn btn-primary" :disabled="estadoboton" > 
                                    <div v-if="estadoboton" class="spinner-border spinner-border-sm mr-2" role="status"></div> <i class="fas fa-save me-2"></i> Guardar
                                </button>
                                <button @click="cancelar()" type="button" class="btn  btn-light" data-dismiss="modal">Cancelar</button>
                            </div>
                            </form>

 
    </div>
</template>


<script>
import { ref, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'

import axios from 'axios'

export default {
    props: ['id','accion'],
    setup(props) {

        const router = useRouter()
        const route = useRoute()

        const titulo = ref()

        const estadoboton = ref(false)
        const permisospadres = ref([])
        const respuesta = ref('')
        const datospermiso = ref({
                                    idpermisos: '',
                                    nombrepermiso: '',
                                    icono:         '',
                                    linkpermiso:   '',
                                    permisos_idpermisos: '',
                                    descripcion: '',
                                    orden: ''
                                })

        const categorias = ref([])
        const categoriasasociadas = ref([])
        const permisosxtipopersona = ref([])


        const ListarPermisos = () => {
            axios.get('/api/permisos').then(response => { permisospadres.value = response.data  })
        }

        const ConsultarCategorias = () => {
            axios.get('/api/tiposdepersonas').then(response => { categorias.value = response.data  })
        }


        const consultarpermiso = () => {
            axios.get('/api/infopermiso/'+props.id).then(response => { 

                    datospermiso.value = response.data;  
                    // categoriasasociadas.value = response.data.permisosxtipopersona

                    let asociados = []
                    for(let i=0; i<response.data.permisosxtipopersona.length; i++)
                    {
                         categoriasasociadas.value[i] = response.data.permisosxtipopersona[i].idpersonascategoria
                    }
                

                })
        }
         
        const validar = () => {

                if(props.accion == 'nuevo')
                {
                   titulo.value = "Nuevo Permiso"
                }else{
                    titulo.value = "Editar Permiso"
                    consultarpermiso()
                }
        }


        const ejecutar = () => {

                if(props.accion == 'nuevo')
                {
                    guardarpermiso()
                }else{
                    editarPermiso()
                }
        }


        const guardarpermiso = () => {

            estadoboton.value = true;

            axios.post('/api/guardarpermiso',{ data: datospermiso.value }).then( response => {
                
                  if(response.data===1)
                  {
                      swal("Bien!", "Matrícula Actualizada Correctamente!", "success");
                    }else{
                      swal(":(", "Intentemos nuevamente!", "error");
                  }

             }).catch(function (error) {
                console.log(error)
                });

            estadoboton.value = false;

        }

        
        const editarPermiso = () => {

            estadoboton.value = true;
            axios.post('/api/editarPermiso',{ data: datospermiso.value, categorias: categoriasasociadas.value  }).then( response => {
                
                if(response.data==1)
                  {
                      swal("Bien!", "Matrícula Actualizada Correctamente!", "success");
                  }else
                  {
                      console.log(response.data)
                  }

             }).catch(function (error) {
                console.log(error)
                });

            estadoboton.value = false;

        }


        const cancelar = () => {
            router.go(-1)
        }

        onMounted(() => {
            ListarPermisos()
            validar()
            ConsultarCategorias()
        })


    return {
        //Variables
        estadoboton,
        permisospadres,
        respuesta,
        datospermiso,
        titulo,
        categorias,
        categoriasasociadas,
        permisosxtipopersona,

        //Funciones
        guardarpermiso, 
        cancelar,
        ejecutar 
        }

    }

}
 
 

</script>